import {MAIN_CONST} from "../../../action/main.action"

const initialState = {

}

export default function reducer(state = initialState, action){
    switch (action.type){
        default:
            return state
    }
}