import {SETTING_CONST} from "../../../action/setting.action";

const initialState = {
    // visible: false, // not work
    // old_password_text: '',
    // new_password_text: '',
    // retry_new_password_text: '',
    id_account: 123456,
    login: 'MyLogin',
    email: "example@domain.com",
}

export default function reducer(state = initialState, action){
    switch (action.type){
        // case SETTING_CONST.CHANGE_VISIBLE:
        //     return {...state, visible: action.payload}
        default:
            return state
    }
}