import './App.css';
import React from "react";
import 'antd/dist/antd.css';
import {Button, Layout, Space, Row, Col, Radio, Typography, Drawer, Menu} from "antd";
import {BellOutlined, CommentOutlined, SearchOutlined, SendOutlined, SettingOutlined} from '@ant-design/icons';

import { connect } from 'react-redux'
import Main from "./component/main";

import {action as setting_action} from './action/setting.action'
import Auth from "./component/auth/auth";
import store from "./store/store.conf";
import Websocket from "./helpers/websocket/Websocket";
import {action as socket_action} from "./action/socket.action";

let maxWidth = 850

const App = (props)=>{
  return (
      <div className={'App'}>
          <Websocket {...props}/>
          <Auth {...props} />
          {/*<Main {...props} />*/}
      </div>
  );
}

// const Settings=()=>{
//     return (
//         <>
//             <Drawer
//               title="Настройки"
//               placement="right"
//               closable={true}
//               // onClose={this.onClose}
//               visible={true}
//               getContainer={true}
//               style={{ position: 'absolute', padding: 0 }}
//             >
//                 {/*<MyAccount />*/}
//                 {/*<MyProfile />*/}
//                 {/*<MyWish />*/}
//                 <Menu>
//                   <Menu.Item>Аккаунт</Menu.Item>
//                   <Menu.Divider/>
//                   <Menu.Item>Профиль</Menu.Item>
//                   <Menu.Divider/>
//                   <Menu.Item>Желания</Menu.Item>
//                 </Menu>
//             </Drawer>
//
//         </>
//
//     )
// }


//
// const MyProfile = ()=>{
//     return(
//         <Drawer
//           title="Мой профиль"
//           placement="right"
//           closable={true}
//           // onClose={this.onClose}
//           visible={true}
//           getContainer={false}
//           style={{ position: 'absolute', padding: 0 }}
//         >
//             Мой профиль
//         </Drawer>
//     )
// }

// const MyWish = ()=>{
//     return(
//         <Drawer
//           title="Мои желания"
//           placement="right"
//           closable={true}
//           // onClose={this.onClose}
//           visible={true}
//           getContainer={true}
//           style={{ position: 'absolute', padding: 0 }}
//         >
//             Мои желания
//         </Drawer>
//     )
// }


const mapStateToProps = store => {
    return {
        store: store
    }
}
const mapDispatchToProps = dispatch => {
    return {
        action:{
            setting: {
                change_visible: (val)=>dispatch(setting_action.change_visible(val)),
                change_select: (val)=>dispatch(setting_action.change_select(val)),
                visible_change_password: (val)=>dispatch(setting_action.visible_change_password(val)),
            },
            websocket: {
                websocket_connect: ()=>dispatch(socket_action.websocket_connect()),
            }
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(App)
