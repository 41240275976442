import { combineReducers } from 'redux';

import main_reducer from './main/main.reducer';
import language_reducer from  './language/language.reducer'
import setting_reducer from './setting/setting.reducer'
import my_account_reducer from './my_account/my_account.reducer'
import socket_reducer from './socket/socket.reducer'


export const rootReducer = combineReducers({
    websocket: socket_reducer,
    main: main_reducer,
    language: language_reducer,
    setting: setting_reducer,
    my_account: my_account_reducer,
})