import {SETTING_CONST} from "../../../action/setting.action";

const initialState = {
    visible: false,
    selectedKeys: '',
    is_show_change_password: false,
    old_password_text: '',
    new_password_text: '',
    retry_new_password_text: '',
}

export default function reducer(state = initialState, action){
    switch (action.type){
        case SETTING_CONST.VISIBLE_CHANGE_PASSWORD:
            return {...state, is_show_change_password: action.payload}
        case SETTING_CONST.CHANGE_SELECT:
            return {...state, selectedKeys: action.payload}
        case SETTING_CONST.CHANGE_VISIBLE:
            return {...state, visible: action.payload}
        default:
            return state
    }
}