const language = {
    main: {
        title: 'Anonymous chat'
    },
    setting: {
        title: 'Properties',
        account: 'My account',
        profile: 'My profile',
        wish: 'My wish',
        change_password: {
            title: 'Change password',
            cancel_text: 'Cancel',
            change_text: 'Change',
            old_password_text: 'Old password',
            new_password_text: 'New password',
            retry_new_password_text: 'Retry new password'
        },
        not_required_text: 'Not required',
        btn_change_password_text: 'Change password',
        btn_del_account_text: 'Delete account',
        id_text: 'ID',
        login_text: 'Login',
        email_text: 'E-mail',
    },
    my_account: {
    },
    my_profile: {
    },
    my_wish: {
    },
    auth: {
        title: "Login",
        login_user: "User",
        login_password: "Password",
        login_register: "Sign up",
        login_login: "Login"
    },
}

export default language