import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import 'antd/dist/antd.variable.css'
// @import 'antd/dist/antd.variable.less';
import {Button, ConfigProvider} from "antd";
import ru_Ru from 'antd/es/locale/ru_RU';
import en_Usa from 'antd/es/locale/en_US'
import store from './store/store.conf'

import Websocket from "./helpers/websocket/Websocket";

ConfigProvider.config(
    {
    // prefixCls: 'custom',
          theme: {
              primaryColor: '#9f45a9',
              // errorColor: '#ff0e0e',
              // infoColor: '#ffdc41',
              // processingColor: '#000',
              // successColor: '#000',
              // warningColor: '#000'
          }
    }
);

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <ConfigProvider>
              <App style={{height: '100%'}} />
          </ConfigProvider>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
