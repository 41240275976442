import {SOCKET_CONST} from '../../../action/socket.action'

const initialState = {
    is_websocket_connect: false
}

export default function reducer(state = initialState, action){
    switch (action.type){
        case SOCKET_CONST.WEBSOCKET_CONNECT:
            return { ...state, is_websocket_connect: action.payload}
        default:
            return state
    }
}