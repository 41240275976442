import {message} from "antd";
// import {GLOBAL_CONST } from './global.action'
// import {USERS_CONST} from './users.action';
// import {NS_CONST, action as ns_action} from "./ns.action";
// function admin
// import {
//     admin__add_company_agent,
//     admin__add_employees_agents, admin__add_user_in_group, admin__create_group,
//     admin__create_user,
//     admin__del_company_agent, admin__del_employees_agents,
//     admin__del_user, admin__get_company, admin__get_employees_agent, admin__get_group,
//     admin__get_users, admin__get_users_in_group,
//     admin__save_company_agent, admin__save_employee_agent,
//     admin__set_employee_agent,
//     admin__set_fio_user,
//     admin__set_is_active_admin_role,
//     admin__set_is_active_agent_role,
//     admin__set_is_active_user,
//     admin__set_login_user,
//     admin__set_password_user, admin__update_group,
//     admin__update_user, admin__update_user_in_group,
//     admin__set_is_active_uis_role,
// } from './helpers_socket/admin'
// import {AGENT_SETTING_COMPANY_CONST} from "./setting_company.action";
// import {AGENT_LEAD_CONST} from "./agent_lead.action";
// import {SALES_CONST} from "./sales.action";
// import {FUNNEL_CONST} from "./funnel.action";
// import {CHAT_CONST} from "./chat.action";
// import {LOGIN_CONST} from "./login.action";
// import {LEAD_INFO_CONST} from "./lead_info.action";
// import {PROFILE_CONST} from "./profile.action";
// import {AGENT_EMPLOYEE_CONST} from "./agent_employee.action";
// import {ADD_LEAD_CONST} from "./add_lead.action";

export const SOCKET_CONST = {
    WEBSOCKET_CONNECT: "WEBSOCKET_CONNECT",
    WEBSOCKET_SEND: "WEBSOCKET_SEND"
}

export const action = {
    websocket_connect,
}


const key_socket = 'socket_message';
let socket = 0 //  new WebSocket("ws://127.0.0.1:20000")
let try_connect_socket = 0
let is_socket_connect = false
let id_socket_timer_reconnect = -1
let reconnect_timeout_ms = 7000
let is_started_reconnect_websocket_onerror = false
let is_started_reconnect_websocket_onclose = false
let auth_hash = ''
const url = (window.location.hostname==="localhost" || window.location.hostname==="192.168.27.44")? "ws://127.0.0.1:20000": "wss://app.freedom-chat.online/"
// const url = "wss://custom.uiscom.ru/partner-websocket/"

function websocket_connect(){
    //console.log('init func websocket_connect')
    return (dispatch, getState) => {
        websocket_start()
        function websocket_start(){
            if(!is_socket_connect){
                // console.log('websocket_start')
                // socket = new WebSocket("ws://127.0.0.1:20000")
                socket = new WebSocket(url)
                socket.onopen = onopen
                socket.onclose = onclose
                socket.onmessage = onmessage
                socket.onerror = onerror
            }
        }
        function onopen() {
            // console.log('onopen')
            if(try_connect_socket !== 1){
                message.success({content: 'Соединение с сервером установлено.', key:key_socket, duration: 2})
            }
            is_started_reconnect_websocket_onerror = false
            is_started_reconnect_websocket_onclose = false
            try_connect_socket = 0
            is_socket_connect = true
            id_socket_timer_reconnect = -1
            setTimeout(
                ()=> dispatch({
                type: SOCKET_CONST.WEBSOCKET_CONNECT,
                payload: true
                }),
                 500
            )
            if(auth_hash !== ''){
                check_auth_hash(auth_hash)
            }
        }
        function onclose(event) {
            if(!is_started_reconnect_websocket_onerror){
                is_started_reconnect_websocket_onclose = true
                //console.log('onclose - not action')
                try_connect_socket++
                is_socket_connect = false
                if(try_connect_socket !== 0){
                    message.error({content: 'Соединение с сервером не установлено. Попытка: ' + try_connect_socket, key:key_socket, duration: 100})
                    dispatch({
                        type: SOCKET_CONST.WEBSOCKET_CONNECT,
                        payload: false
                    })
                }
                if(id_socket_timer_reconnect !== -1) {
                    clearTimeout(id_socket_timer_reconnect);
                    id_socket_timer_reconnect = -1
                }
                id_socket_timer_reconnect = setTimeout(websocket_start, try_connect_socket===1?0:reconnect_timeout_ms)


            }
        }
        function onmessage(event) {
            // console.log('onmessage')
            let data = JSON.parse(event.data)
            if ((getState().global.is_auth) || (data.type === 'auth') || (data.type === 'check_auth') || (data.type = 'permission')) {
                // switch (data.type) {
                //     case 'auth':
                //         if(!data.is_auth){
                //             message.error({content: "Логин или пароль не верный", duration: 5})
                //         }else{
                //             auth_hash = data.auth_hash
                //         }
                //         dispatch(
                //             {
                //                 type: GLOBAL_CONST.LOGIN_ACTION,
                //                 payload: data
                //             }
                //         )
                //         break
                //     case 'logout':
                //         dispatch(
                //             {
                //                 type: GLOBAL_CONST.SET_LOGOUT,
                //                 payload: data
                //             }
                //         )
                //         dispatch( {
                //             type: LOGIN_CONST.SET_LOGIN_VALUE,
                //             payload: ''
                //         })
                //         dispatch( {
                //             type: LOGIN_CONST.SET_PASSWORD_VALUE,
                //             payload: ''
                //         })
                //         break
                //     case 'check_auth':
                //         if(data.is_auth){
                //             auth_hash = data.auth_hash
                //         }else{
                //             auth_hash = ''
                //         }
                //         dispatch(
                //             {
                //                 type: GLOBAL_CONST.LOGIN_ACTION,
                //                 payload: data
                //             }
                //         )
                //         break
                //     case 'permission':
                //         dispatch(
                //             {
                //                 type: GLOBAL_CONST.UPDATE_PERMISSION,
                //                 payload: data
                //             }
                //         )
                //         break
                //     default:
                //
                // }
            }
        }
        function onerror(error) {
            if(!is_started_reconnect_websocket_onclose){
                is_started_reconnect_websocket_onerror = true
                //console.log('onerror')
                try_connect_socket++
                is_socket_connect = false
                if(try_connect_socket !== 1){
                    message.error({content: 'Соединение с сервером не установлено. Попытка: ' + try_connect_socket, key:key_socket, duration: 100})
                    dispatch({
                        type: SOCKET_CONST.WEBSOCKET_CONNECT,
                        payload: false
                    })
                }
                if(id_socket_timer_reconnect !== -1) {
                    clearTimeout(id_socket_timer_reconnect);
                    id_socket_timer_reconnect = -1
                }
                id_socket_timer_reconnect = setTimeout(websocket_start, try_connect_socket===1?0:reconnect_timeout_ms)


            }
        }
    }
}
let id_timer_resend = -1
export function websocket_send(data){
    // console.log('websocket_send', data, socket.readyState, socket)
    try {
        if(socket.readyState !== 1){
            id_timer_resend = setTimeout(function (){
                websocket_send(data);
            },1000);
        }else{
            // console.log('websocket_sends')
            socket.send(JSON.stringify(data));
        }
    }catch {
        //console.log('websocket_send - ERROR', data, socket)
        id_timer_resend = setTimeout(function (){
            websocket_send(data);
        },1000);
    }
}

// function auth(login, password) {
//     websocket_send(
//         {type: 'auth', login: login, password: password}
//     )
//     return(
//         {
//             type: SOCKET_CONST.WEBSOCKET_SEND,
//             payload: null
//         }
//     )
// }

function check_auth_hash(hash){
    //console.log('check_auth_hash')
    websocket_send(
        {type: 'check_user_hash', auth_hash: hash}
    )
}

export const defaultDispatchSocketSend = {
    type: SOCKET_CONST.WEBSOCKET_SEND,
    payload: null
}

// function update_permissions(){
//     websocket_send({type: 'get_permission'})
//     return defaultDispatchSocketSend
// }
