import React from 'react'

let is_running = false
const Websocket=(props)=>{
    console.log(props)
    if (!is_running){
        is_running = true
        { props.action.websocket.websocket_connect() }
    }
    return null
}

export default Websocket;