export const SETTING_CONST = {
    NO_ACTION: 'SETTING_NO_ACTION',
    CHANGE_VISIBLE: 'SETTING_CHANGE_VISIBLE',
    CHANGE_SELECT: 'SETTING_CHANGE_SELECT',
    VISIBLE_CHANGE_PASSWORD: 'SETTING_VISIBLE_CHANGE_PASSWORD',
}

export const action = {
    change_visible,
    change_select,
    visible_change_password,
}

function change_visible(val){
    return {
        type: SETTING_CONST.CHANGE_VISIBLE,
        payload: val
    }
}

function change_select(val){
    return {
        type: SETTING_CONST.CHANGE_SELECT,
        payload: val
    }
}

function visible_change_password(val){
    return {
        type: SETTING_CONST.VISIBLE_CHANGE_PASSWORD,
        payload: val
    }
}