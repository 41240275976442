import {Button, Col, Input, Modal, Row} from "antd";
import {LineOutlined, LockOutlined, UserOutlined} from "@ant-design/icons";
import styles from "./auth.module.css"

const Auth = (props)=>{
    let lng = props.store.language
    return (
        <Modal
          className={styles.auth}
          title={lng.auth.title}
          centered
          visible={true}
          maskClosable={false}
          footer={[
              <Row style={{width: '100%'}}>
                  <Col flex={1}>

                  </Col>
                  <Col flex={1}>
                      <Button type="primary" disabled>{lng.auth.login_register}</Button>
                  </Col>
              </Row>
          ]}

          // title={[]}
          closable={false}
          maskStyle={{backgroundSize: 'cover', backgroundImage: 'url(/img/login_bg.jpg)'}}
          bodyStyle={{boxShadow: '0em 0em 20px 0px rgb(222 138 200 / 50%)', borderRadius:0, backgroundSize: 'cover', backgroundImage: 'url(/img/privacy.jpg)'}}
          style={{opacity: 0.9, boxShadow: '0em 0em 19px 0px rgb(116 80 152)', paddingBottom: 0}}
            // onOk={() => this.setModal2Visible(false)}
          // onCancel={() => this.setModal2Visible(false)}
        >
            <Row>
                <Col>

                </Col>
                <Col>
                    <Input size="large" placeholder={lng.auth.login_user} prefix={
                        <><UserOutlined /><LineOutlined style={{color: '#bbb'}} rotate={90} /></>
                    } />
                    <br></br>
                    <br></br>
                    <Input.Password size="large" placeholder={lng.auth.login_password} prefix={
                        <><LockOutlined /><LineOutlined style={{color: "#bbb"}} rotate={90} /></>
                    }/>
                    <br></br>
                    <br></br>
                    <Button type="primary" style={{float: 'right'}}>{lng.auth.login_login}</Button>
                </Col>
            </Row>

        </Modal>
    )
}

export default Auth